import PDFDocument from "pdfkit/js/pdfkit";
import blobStream from "blob-stream";
import axios from "axios";

import logo from "./images/logo.png";

const renderNumber = (doc, { number }) => {
  const [width, height] = [129, 24];
  const padding = 2;

  doc.save();

  doc.translate(35, 22);

  doc.rect(0, 0, width, height).stroke();

  doc.translate(padding, padding);

  doc
    .fontSize(10)
    .text(`Game No:`, 0, 0, { width: 33, align: "left" })
    .fontSize(8)
    .text(number, 20, 13);

  doc.restore();
};

const renderDate = (doc, { startDate }) => {
  doc.save();

  doc.translate(35, 77);

  renderField(doc, {
    label: "Date",
    value: startDate,
    width: 198
  });

  doc.restore();
};

const renderLocation = (doc, { location }) => {
  doc.save();

  doc.translate(35, 94);

  renderField(doc, { label: "Location of Game", value: location, width: 198 });

  doc.restore();
};

const renderDivision = (doc, { division }) => {
  doc.save();

  doc.translate(254, 77);

  renderField(doc, { label: "Division", value: division, width: 158 });

  doc.restore();
};

const renderLeague = (doc, { league }) => {
  doc.save();

  doc.translate(254, 94);

  renderField(doc, { label: "League", value: league, width: 158 });

  doc.restore();
};

const renderCategory = (doc, { category }) => {
  doc.save();

  doc.translate(422, 77);

  renderField(doc, { label: "Category", value: category, width: 192 });

  doc.restore();
};

const renderPlayoff = (doc, { gamesPlayed, gamesTotal }) => {
  const gamesPlayedWidth = 40;
  const gamesTotalWidth = 32;

  doc.save();

  doc.translate(422, 94);

  doc.text("PLAYOFF GAME", 0, 0);

  doc.translate(120, 0);

  renderField(doc, {
    label: "GAME",
    value: gamesPlayed > 0 ? gamesPlayed : "",
    width: gamesPlayedWidth
  });

  doc.translate(gamesPlayedWidth, 0);

  renderField(doc, {
    label: "OF",
    value: gamesTotal > 0 ? gamesTotal : "",
    width: gamesTotalWidth
  });

  doc.restore();
};

const renderField = (doc, { label, value, width }) => {
  doc.save();

  doc.fontSize(8);

  doc.text(label, 0, 0);

  const lineHeight = doc.currentLineHeight();
  const labelWidth = doc.widthOfString(label);
  const valueWidth = width - labelWidth;

  doc.translate(labelWidth, 0);

  doc.text(value, 0, 0, { width: valueWidth, align: "center" });

  doc.translate(0, lineHeight);

  doc
    .moveTo(0, 0)
    .lineTo(valueWidth, 0)
    .stroke();

  doc.restore();
};

const renderScore = (doc, { finalScore: { home, visitor } }) => {
  const renderTeam = (doc, team) => {
    doc
      .save()
      .fontSize(10)
      .fillColor("red")
      .text(team, 0, 0)
      .restore();
  };

  const renderTeamScore = (doc, score) => {
    const fieldWidth = 24;

    doc
      .save()
      .fontSize(18)
      .text(score, 0, 5, { width: fieldWidth, align: "center" })
      .restore()
      .rect(0, 0, fieldWidth, fieldWidth)
      .stroke();
  };

  doc.save().translate(422, 22);

  doc.text("FINAL\nSCORE", 0, 0);

  doc.translate(47, 0);

  renderTeam(doc, "HOME");

  doc.translate(37, 0);

  renderTeamScore(doc, home);

  doc.translate(33, 0);

  renderTeam(doc, "VISITOR");

  doc.translate(47, 0);

  renderTeamScore(doc, visitor);

  doc.restore();
};

const renderGameType = (doc, { gameType }) => {
  const [width, height] = [75, 60];
  const margin = 5;
  const checkboxSize = 4;

  doc.save().translate(630, 22);

  doc.rect(0, 0, width, height).stroke();

  doc.translate(margin, margin);

  doc.fontSize(6);

  doc.text("GAME TYPE", 0, 0, { width: width - 2 * margin, align: "center" });

  doc.translate(0, 6);

  const types = [
    { label: "REGULAR SEASON", value: "regular_season" },
    { label: "EXHIBITION", value: "exhibition" },
    { label: "TOURNAMENT", value: "tournament" },
    { label: "PLAYOFF", value: "playoff" }
  ];

  for (let t of types) {
    doc
      .translate(0, 10)
      .text(t.label, 11, 0)
      .rect(0, 0, checkboxSize, checkboxSize);

    if (t.value === gameType) {
      doc.fillAndStroke();
    } else {
      doc.stroke();
    }
  }

  doc.restore();
};

const renderScorekeeper = (doc, { scorekeeper: { name, phone } }) => {
  const [fieldWidth, fieldHeight] = [186, 22];
  const padding = 2;

  const renderScorekeeperField = (doc, { label, value }) => {
    doc
      .rect(0, 0, fieldWidth, fieldHeight)
      .stroke()
      .save()
      .text(label, padding, padding)
      .text(value, 72, padding, { width: 72, align: "left" })
      .restore();
  };

  doc.save().translate(730, 22);

  renderScorekeeperField(doc, { label: "OFFICIAL SCORER", value: name });

  doc.translate(0, fieldHeight);

  doc
    .save()
    .moveTo(0, 0)
    .lineTo(fieldWidth, 0)
    .lineWidth(1)
    .stroke()
    .restore();

  renderScorekeeperField(doc, { label: "Phone #", value: phone });

  doc.restore();
};

async function renderCurfew(doc, { curfew: { time, homeCoachSignature, visitorCoachSignature } }) {
  const height = 32;
  const timeWidth = 72;
  const signatureWidth = 29;

  const padding = 2;

  doc.save();

  doc.translate(730, 69);

  doc
    .rect(0, 0, timeWidth, height)
    .stroke()
    .text("Curfew Time", padding, padding);

  if (time) {
    doc.text(time);
  }

  doc.translate(timeWidth, 0);

  doc
    .save()
    .moveTo(0, 0)
    .lineTo(0, height)
    .lineWidth(1)
    .stroke()
    .restore();

  if (visitorCoachSignature) {
    await asyncImage(doc, visitorCoachSignature, 0, 8, {
      width: signatureWidth
    });
  }

  doc
    .text("V init", 0, padding, { width: signatureWidth, align: "center" })
    .rect(0, 0, signatureWidth, height)
    .stroke();

  doc.translate(signatureWidth, 0);

  if (homeCoachSignature) {
    await asyncImage(doc, homeCoachSignature, 0, 8, {
      width: signatureWidth
    });
  }

  doc
    .text("H init", 0, padding, { width: signatureWidth, align: "center" })
    .rect(0, 0, signatureWidth, height)
    .stroke();

  doc.restore();
}

function renderFlood(doc, { firstFlood, secondFlood }) {
  const [floodWidth, floodHeight] = [54, 32];
  const floodMargin = 2;

  function renderFloodItem(doc, { label, selected }) {
    const checkboxSize = 4;

    doc.text(label, 9, 0).rect(0, 0, checkboxSize, checkboxSize);

    if (selected) {
      doc.fillAndStroke();
    } else {
      doc.stroke();
    }

    doc.translate(0, 7);
  }

  doc.save().translate(862, 69);

  doc.rect(0, 0, floodWidth, floodHeight).stroke();

  doc.translate(floodMargin, floodMargin).fontSize(6);

  doc.text("Flood Between", 0, 0);

  doc.translate(0, 9);

  renderFloodItem(doc, { label: "1st & 2nd", selected: firstFlood });

  renderFloodItem(doc, { label: "2nd & 3rd", selected: secondFlood });

  renderFloodItem(doc, {
    label: "None",
    selected: !(firstFlood || secondFlood)
  });

  doc.restore();
}

const renderLengthOfGame = (doc, game) => {
  const periods = game.periods || {};
  const [width, height] = [54, 79];
  const margin = 2;

  const gameDetails = gameLengthDetails(game)

  doc.save();

  doc.translate(941, 22);

  doc.rect(0, 0, width, height).stroke();

  doc.translate(margin, margin).fontSize(6);

  doc.text("Length of Game", 0, 0, {
    width: width - 2 * margin,
    align: "center"
  });

  const periodsArray = Object.entries(periods).sort(([keyA], [keyB]) => keyA.localeCompare(keyB, undefined, {numeric: true}));

  const hasOT = periodsArray.length > gameDetails.nonOTPeriods.length;
  const rows = gameDetails.nonOTPeriods.length + (hasOT ? 1 : 0);
  const lineHeight = rows >= 5 ? 12 : 16;

  const labels = ["1st", "2nd", "3rd", "4th"];
  for (let i = 0; i < gameDetails.nonOTPeriods.length; i++) {
    doc.translate(0, lineHeight);

    doc
      .text(labels[i], 0, 0)
      .moveTo(10, 6)
      .lineTo(36, 6)
      .stroke()
      .text("Stop", 36, 0);

    doc.text(periodsArray[i][1], 18, 0);
  }

  if (hasOT) {
    doc.translate(0, lineHeight);

    doc
      .text("OT", 0, 0)
      .moveTo(10, 6)
      .lineTo(36, 6)
      .stroke()
      .text("S/V", 36, 0);

    doc.text(periodsArray[gameDetails.nonOTPeriods.length][1], 18, 0);
  }

  doc.restore();
};

const playerLastNameSort = (a, b) => {
  return a.lastName.localeCompare(b.lastName);
};

const renderPlayers = (doc, { label, title, players: teamPlayers, injuredPlayers }) => {
  const goalies = teamPlayers.filter(({ position }) => position === "goalie").sort(playerLastNameSort);
  const players = teamPlayers.filter(({ position }) => position !== "goalie").sort(playerLastNameSort);

  const rows = 20;
  const rowWidth = 172;
  const rowHeight = 12;
  const labelHeight = 24;
  const headerHeight = 15;
  const numberColumnWidth = 18;
  const nameColumnWidth = rowWidth - numberColumnWidth;
  const padding = 4;

  doc.save();

  // Label
  doc
    .save()
    .rect(0, 0, rowWidth, labelHeight)
    .stroke()
    .fontSize(10)
    .fillColor("red")
    .text(label, padding, padding)
    .restore()
    .save()
    .fontSize(6)
    .text(title)
    .restore();

  doc.translate(0, labelHeight);

  doc.save();

  // Header
  doc
    .rect(0, 0, numberColumnWidth, headerHeight)
    .stroke()
    .fontSize(9)
    .text("NO.", 1, padding, { width: numberColumnWidth, align: "center" });

  doc.translate(numberColumnWidth, 0);

  doc
    .rect(0, 0, nameColumnWidth, headerHeight)
    .stroke()
    .fontSize(5)
    .text("AP — AFFILIATED PLAYER C — CAPTAIN A — ALTERNATE", 0, padding + 2, {
      width: nameColumnWidth,
      align: "center"
    });

  doc.restore();

  doc.translate(0, headerHeight);

  // Goalies

  for (let { number, name, starting } of goalies) {
    doc.save();

    doc
      .rect(0, 0, numberColumnWidth, rowHeight)
      .stroke()
      .text(number, 0, padding, { width: numberColumnWidth, align: "center" });

    doc.translate(numberColumnWidth, 0);

    doc
      .rect(0, 0, nameColumnWidth, rowHeight)
      .stroke()
      .text("G", 0, padding, { width: numberColumnWidth, align: "center" })
      .text(name, 0, padding, {
        width: nameColumnWidth,
        align: "center",
        oblique: starting
      });

    doc.restore();

    doc.translate(0, rowHeight);
  }

  // Line

  doc
    .save()
    .moveTo(0, 0)
    .lineTo(rowWidth, 0)
    .lineWidth(1.5)
    .stroke()
    .restore();

  // Players

  for (let { number, name, duty, starting, affiliated } of players) {
    doc.save();

    doc
      .rect(0, 0, numberColumnWidth, rowHeight)
      .stroke()
      .text(number, 0, padding, { width: numberColumnWidth, align: "center" });

    doc.translate(numberColumnWidth, 0);

    doc
      .rect(0, 0, nameColumnWidth, rowHeight)
      .stroke()
      .text(name, 0, padding, {
        width: nameColumnWidth,
        align: "center",
        oblique: starting
      });

    let label = [];

    if (affiliated) {
      label.push("AP");
    }

    duty = { captain: "C", alternate_captain: "A" }[duty];

    if (duty) {
      label.push(duty);
    }

    doc.text(label.join(", "), 0, padding, {
      width: numberColumnWidth,
      align: "center"
    });

    doc.restore();

    doc.translate(0, rowHeight);
  }

  for (let { number, name, duty, starting, affiliated } of injuredPlayers) {
    doc.save();

    doc
      .rect(0, 0, numberColumnWidth, rowHeight)
      .stroke()
      .text(number, 0, padding, {
        width: numberColumnWidth,
        align: "center",
        strike: true
      });

    doc.translate(numberColumnWidth, 0);

    doc
      .rect(0, 0, nameColumnWidth, rowHeight)
      .stroke()
      .text(`${name}`, 0, padding, {
        width: nameColumnWidth,
        align: "center",
        oblique: starting,
        strike: true
      })
      .text("(INJ)", nameColumnWidth - numberColumnWidth, padding);

    let label = [];

    if (affiliated) {
      label.push("AP");
    }

    duty = { captain: "C", alternate_captain: "A" }[duty];

    if (duty) {
      label.push(duty);
    }

    doc.text(label.join(", "), 0, padding, {
      width: numberColumnWidth,
      align: "center"
    });

    doc.restore();

    doc.translate(0, rowHeight);
  }

  // Blank

  for (var i = 0; i < rows - goalies.length - players.length; i++) {
    doc.save();

    doc.rect(0, 0, numberColumnWidth, rowHeight).stroke();

    doc.translate(numberColumnWidth, 0);

    doc.rect(0, 0, nameColumnWidth, rowHeight).stroke();

    doc.restore();

    doc.translate(0, rowHeight);
  }

  doc.restore();
};

const renderHomePlayers = (doc, { home: { players, injuredPlayers, title } }) => {
  doc.save().translate(35, 114);

  renderPlayers(doc, {
    label: "HOME",
    title,
    players,
    injuredPlayers
  });

  doc.restore();
};

const renderVisitorPlayers = (doc, { visitor: { players, injuredPlayers, title } }) => {
  doc.save().translate(517, 114);

  renderPlayers(doc, {
    label: "VISITOR",
    title,
    players,
    injuredPlayers
  });

  doc.restore();
};

const renderSuspended = (doc, suspendedMembers) => {
  const rows = 4;
  const rowWidth = 172;
  const rowHeight = 12;
  const padding = 4;

  doc.save();

  doc
    .rect(0, 0, rowWidth, rowHeight)
    .stroke()
    .fontSize(6)
    .text("List Suspended Players/Officials NO. of Games", 0, padding, {
      width: rowWidth,
      align: "center"
    });

  doc.translate(0, rowHeight);

  // Suspended

  for (var { name, number, length } of suspendedMembers) {
    doc.save();

    doc
      .rect(0, 0, rowWidth, rowHeight)
      .stroke()
      .text(name, 0, padding, {
        width: rowWidth / 2,
        align: "center"
      })
      .text(`${number} of ${length}`, rowWidth / 2, padding, {
        width: rowWidth / 2,
        align: "center"
      });

    doc.restore();

    doc.translate(0, rowHeight);
  }

  // Blank

  for (var i = 0; i < rows - suspendedMembers.length; i++) {
    doc.rect(0, 0, rowWidth, rowHeight).stroke();

    doc.translate(0, rowHeight);
  }

  doc.restore();
};

const renderHomeSuspended = (doc, { home: { suspendedMembers } }) => {
  doc.save().translate(35, 393);

  renderSuspended(doc, suspendedMembers);

  doc.restore();
};

const renderVisitorSuspended = (doc, { visitor: { suspendedMembers } }) => {
  doc.save().translate(517, 393);

  renderSuspended(doc, suspendedMembers);

  doc.restore();
};

const renderPenalties = (doc, { label, penalties }, game) => {
  const headerHeight = 13.5;
  const rowWidth = 200;
  const rowHeight = 12;
  const padding = 4;
  const rows = 24;

  const gameLength = gameLengthDetails(game)

  const columns = [
    {
      title: gameLength.shortPeriodName,
      width: 19
    },
    {
      title: "NO.",
      width: 18
    },
    {
      title: "MIN.",
      width: 21
    },
    {
      title: "Code Infraction",
      width: 54
    },
    {
      title: "OFF",
      width: 29
    },
    {
      title: "START",
      width: 29
    },
    {
      title: "ON",
      width: 30
    }
  ];

  doc.save();

  doc
    .rect(0, 0, rowWidth, headerHeight)
    .stroke()
    .fontSize(7)
    .text(label, 0, padding, {
      width: rowWidth,
      align: "center"
    });

  doc.translate(0, headerHeight);

  doc.save();

  for (var { title, width } of columns) {
    doc
      .rect(0, 0, width, headerHeight)
      .stroke()
      .text(title, 0, padding, { width, align: "center" });

    doc.translate(width, 0);
  }

  doc.restore();

  doc.translate(0, headerHeight);

  for (var { period, member, length, code, timeOff, timeStart, timeOn } of penalties) {
    doc.save();

    doc
      .rect(0, 0, columns[0].width, rowHeight)
      .stroke()
      .text(setPeriodName(period), 0, padding, { width: columns[0].width, align: "center" });

    doc.translate(columns[0].width, 0);

    doc
      .rect(0, 0, columns[1].width, rowHeight)
      .stroke()
      .text(member, 0, padding, { width: columns[1].width, align: "center" });

    doc.translate(columns[1].width, 0);

    doc
      .rect(0, 0, columns[2].width, rowHeight)
      .stroke()
      .text(length, 0, padding, { width: columns[2].width, align: "center" });

    doc.translate(columns[2].width, 0);

    doc
      .rect(0, 0, columns[3].width, rowHeight)
      .stroke()
      .text(code, 0, padding, { width: columns[3].width, align: "center" });

    doc.translate(columns[3].width, 0);

    doc
      .rect(0, 0, columns[4].width, rowHeight)
      .stroke()
      .text(timeOff, 0, padding, { width: columns[4].width, align: "center" });

    doc.translate(columns[4].width, 0);

    doc
      .rect(0, 0, columns[5].width, rowHeight)
      .stroke()
      .text(timeStart, 0, padding, {
        width: columns[5].width,
        align: "center"
      });

    doc.translate(columns[5].width, 0);

    doc
      .rect(0, 0, columns[6].width, rowHeight)
      .stroke()
      .text(timeOn, 0, padding, { width: columns[6].width, align: "center" });

    doc.translate(columns[6].width, 0);

    doc.restore();

    doc.translate(0, rowHeight);
  }

  // Blank

  for (var i = 0; i < rows - penalties.length; i++) {
    doc.save();

    for (var { width: columnWidth } of columns) {
      doc.rect(0, 0, columnWidth, rowHeight).stroke();

      doc.translate(columnWidth, 0);
    }

    doc.restore();

    doc.translate(0, rowHeight);
  }

  doc.restore();
};

const renderHomePenalties = (doc, game) => {
  doc.save().translate(210, 114);

  renderPenalties(doc, {
    label: "HOME PENALTIES",
    penalties: game.home.penalties,
    }, 
    game);

  doc.restore();
};

const renderVisitorPenalties = (doc, game) => {
  doc.save().translate(692, 114);

  renderPenalties(doc, {
    label: "VISITOR PENALTIES",
    penalties: game.visitor.penalties
    },
    game
  );

  doc.restore();
};

const asyncImage = (doc, url, ...rest) =>
  axios
    .get(url, { responseType: "arraybuffer" })
    .then(function (response) {
      doc.image(Buffer.from(response.data), ...rest);
    })
    .catch(function (error) {
      console.log(error);
    });

const renderScoring = (doc, { label, goals }, game) => {
  const headerHeight = 13.5;
  const rowWidth = 100;
  const rowHeight = 12;
  const padding = 4;
  const rows = 24;

  const gameLength = gameLengthDetails(game)

  const columns = [
    {
      title: gameLength.shortPeriodName,
      width: 18
    },
    {
      title: "TIME",
      width: 28
    },
    {
      title: "G",
      width: 18
    },
    {
      title: "A",
      width: 18
    },
    {
      title: "A",
      width: 18
    }
  ];

  doc.save();

  doc
    .rect(0, 0, rowWidth, headerHeight)
    .stroke()
    .fontSize(7)
    .text(label, 0, padding, {
      width: rowWidth,
      align: "center"
    });

  doc.translate(0, headerHeight);

  doc.save();

  for (var { title, width } of columns) {
    doc
      .rect(0, 0, width, headerHeight)
      .stroke()
      .text(title, 0, padding, { width, align: "center" });

    doc.translate(width, 0);
  }

  doc.restore();

  doc.translate(0, headerHeight);

  for (var { period, time, scorer, assistA, assistB } of goals) {
    doc.save();

    doc
      .rect(0, 0, columns[0].width, rowHeight)
      .stroke()
      .text(setPeriodName(period), 0, padding, { width: columns[0].width, align: "center" });

    doc.translate(columns[0].width, 0);

    doc
      .rect(0, 0, columns[1].width, rowHeight)
      .stroke()
      .text(time, 0, padding, { width: columns[1].width, align: "center" });

    doc.translate(columns[1].width, 0);

    doc
      .rect(0, 0, columns[2].width, rowHeight)
      .stroke()
      .text(scorer, 0, padding, { width: columns[2].width, align: "center" });

    doc.translate(columns[2].width, 0);

    doc
      .rect(0, 0, columns[3].width, rowHeight)
      .stroke()
      .text(assistA, 0, padding, { width: columns[3].width, align: "center" });

    doc.translate(columns[3].width, 0);

    doc
      .rect(0, 0, columns[4].width, rowHeight)
      .stroke()
      .text(assistB, 0, padding, { width: columns[4].width, align: "center" });

    doc.translate(columns[4].width, 0);

    doc.restore();

    doc.translate(0, rowHeight);
  }

  // Blank

  for (var i = 0; i < rows - goals.length; i++) {
    doc.save();

    for (var { width: columnWidth } of columns) {
      doc.rect(0, 0, columnWidth, rowHeight).stroke();

      doc.translate(columnWidth, 0);
    }

    doc.restore();

    doc.translate(0, rowHeight);
  }

  doc.restore();
};

const renderHomeScoring = (doc, game) => {
  doc.save().translate(413, 114);

  renderScoring(doc, { label: "HOME SCORING", goals: game.home.goals }, game);

  doc.restore();
};

const renderVisitorScoring = (doc, game) => {
  doc.save().translate(895, 114);

  renderScoring(doc, { label: "VISITOR SCORING", goals: game.visitor.goals }, game);

  doc.restore();
};

const renderSummary = (doc, { label, totalPenaltyMinutes, score }) => {
  const [width, height] = [303, 24];

  const minutesColumnWidth = 58;
  const scoreColumnWidth = 54;
  const padding = 3;

  doc.save();

  doc
    .rect(0, 0, width, height)
    .stroke()
    .save()
    .fillColor("red")
    .fontSize(14)
    .text(label, 0, 7, { width, align: "center" })
    .restore();

  doc
    .rect(0, 0, minutesColumnWidth, height)
    .stroke()
    .fontSize(16)
    .text(totalPenaltyMinutes, 0, 6, {
      width: minutesColumnWidth,
      align: "center"
    })
    .fontSize(6)
    .text("Total\nPenalty\nMinutes", minutesColumnWidth + padding, padding, {
      width: 50,
      align: "left"
    })
    .text("Total\nGoals", minutesColumnWidth + 138, 10, {
      width: 50,
      align: "right"
    });

  doc.translate(width - scoreColumnWidth, 0);

  doc
    .rect(0, 0, scoreColumnWidth, height)
    .stroke()
    .fontSize(16)
    .text(score, 0, 6, { width: scoreColumnWidth, align: "center" });

  doc.restore();
};

const renderHomeSummary = (doc, { home: { totalPenaltyMinutes, goals } }) => {
  doc.save().translate(210, 429);

  renderSummary(doc, {
    label: "HOME",
    score: goals.length,
    totalPenaltyMinutes
  });

  doc.restore();
};

const renderVisitorSummary = (doc, { visitor: { totalPenaltyMinutes, goals } }) => {
  doc.save().translate(692, 429);

  renderSummary(doc, {
    label: "VISITOR",
    score: goals.length,
    totalPenaltyMinutes
  });

  doc.restore();
};

const renderShotSummary = (doc, { goalieStats }) => {
  const rowHeight = 14;
  const padding = 5;


  const firstColumnWidth = 44;
  const periodColumnWidths = [...Object.keys(goalieStats.periods).map(() => 18), 36];
  const minutesColumnWidth = 50;

  const totalWidth = firstColumnWidth + periodColumnWidths.reduce((sum, val) => sum + val, 0) + minutesColumnWidth;
  // max safe total width = 225
  if (totalWidth < 225) {
    doc.save().translate((225 - totalWidth) / 2.0, 0);
  }

  const periodNames = goalieStats.periods.map(period => {
    if (period.length === 1) {
      return period;
    } else {
      const otNumber = period.match(/\d+/)[0];
      return `OT${otNumber}`;
    }
  }).concat(["TOTAL"]);

  const values = goalieStats.stats
    .filter(stat => stat.toi > 0 || Object.values(stat.shots).reduce((sum, val) => sum + val, 0) > 0)
    .map(stat => {
      const theseShots = goalieStats.periods.map(p => stat.shots[p] || 0);
      const totalShots = Object.values(stat.shots).reduce((sum, val) => sum + val, 0);
      return {
        title: stat.jersey,
        shots: [...theseShots, totalShots],
        minutes: (stat.toi / 60).toFixed(2),
      }
    });

  const totalsValue = values.reduce((collector, elem) => {
    return {
      title: "TOTAL",
      shots: elem.shots.map((shot, i) => shot + (collector.shots[i] || 0)),
      minutes: (Number(collector.minutes || 0) + Number(elem.minutes)).toFixed(2),
    }
  }, { shots: [] });

  values.push(totalsValue);

  // first column
  doc.rect(0, 0, firstColumnWidth, rowHeight * 2).stroke();
  doc.text("GOALIE", padding, rowHeight / 2 + padding, {
    width: firstColumnWidth,
    align: "left"
  });

  values.forEach((val, v) => {
    doc.rect(0, rowHeight * (v + 2), firstColumnWidth, rowHeight).stroke();
    doc.text(val.title, padding, rowHeight * (v + 2) + padding, {
      width: firstColumnWidth,
      align: "left"
    });
  });

  // SHOTS columns
  doc.save().translate(firstColumnWidth, 0);

  const shotsWidth = periodColumnWidths.reduce((sum, num) => sum + num, 0);
  doc.rect(0, 0, shotsWidth, rowHeight).stroke();
  doc.text("SHOTS", padding, padding, {
    width: shotsWidth,
    align: "left"
  });

  periodColumnWidths.forEach((periodColumnWidth, i) => {
    const translation = periodColumnWidths.slice(0, i).reduce((sum, num) => sum + num, 0);
    doc.save().translate(translation, 0);

    doc.rect(0, rowHeight * 1, periodColumnWidth, rowHeight).stroke();
    doc.text(periodNames[i], padding, rowHeight + padding, {
      width: periodColumnWidth,
      align: "left"
    });

    values.forEach((val, v) => {
      doc.rect(0, rowHeight * (v + 2), periodColumnWidth, rowHeight).stroke();
      doc.text(val.shots[i], padding, rowHeight * (v + 2) + padding, {
        width: periodColumnWidth,
        align: "left"
      });
    });

    doc.restore();
  });

  doc.restore();

  // final column
  doc.save().translate(firstColumnWidth + shotsWidth, 0);

  doc.rect(0, 0, minutesColumnWidth, rowHeight * 2).stroke();
  doc.text("MINUTES PLAYED", padding, rowHeight / 4 + padding, {
    width: minutesColumnWidth,
    align: "left"
  });

  values.forEach((val, v) => {
    doc.rect(0, rowHeight * (v + 2), minutesColumnWidth, rowHeight).stroke();
    doc.text(val.minutes, padding, rowHeight * (v + 2) + padding, {
      width: minutesColumnWidth,
      align: "left"
    });
  });

  doc.restore();

  if (totalWidth < 225) {
    doc.restore();
  }
}

const renderHomeShots = (doc, game) => {
  doc.save().translate(288, 456);

  renderShotSummary(doc, { goalieStats: game.home.goalieStats });

  doc.restore();
}

const renderVisitorShots = (doc, game) => {
  doc.save().translate(770, 456);

  renderShotSummary(doc, { goalieStats: game.visitor.goalieStats });

  doc.restore();
}

async function renderCoaches(doc, { coaches }) {
  const rowHeight = 14;
  const firstColumnWidth = 190;
  const secondColumnWidth = 60;
  const width = firstColumnWidth + secondColumnWidth;
  const padding = 5;
  const imagePadding = 1;

  doc.save().fontSize(6);

  async function renderCoach(doc, coach) {
    if (typeof coach === "object") {
      const { name, signature } = coach;

      doc.text(name, -(secondColumnWidth + padding), padding, {
        width,
        align: "right"
      });

      if (signature) {
        await asyncImage(doc, signature, firstColumnWidth + imagePadding, imagePadding, {
          height: rowHeight - 2 * imagePadding
        });
      }
    }

    doc.translate(0, rowHeight);
  }

  let rows = [
    {
      label: "Head Coach"
    },
    {
      label: "Trainer"
    },
    {
      label: "Manager, Asst. Coach or Asst. Trainer"
    },
    {
      label: "Manager, Asst. Coach or Asst. Trainer"
    },
    {
      label: "Manager, Asst. Coach or Asst. Trainer"
    }
  ];

  // Blank
  doc.save();

  for (var { label } of rows) {
    doc
      .rect(0, 0, firstColumnWidth, rowHeight)
      .stroke()
      .text(label, padding, padding);

    doc.save().translate(firstColumnWidth, 0);

    doc.rect(0, 0, secondColumnWidth, rowHeight).stroke();

    doc.restore();

    doc.translate(0, rowHeight);
  }

  doc.restore();

  // Head Coach
  var headCoach = coaches.find(c => c.status === "coaching" && c.position === "Head Coach");

  // or Head Coach at Large
  if (headCoach === undefined) {
    headCoach = coaches.find(c => c.status === "coaching" && c.position === "Head Coach at Large");
  }

  await renderCoach(doc, headCoach);

  // Trainer
  var trainer = coaches.find(c => c.status === "coaching" && c.position === "Trainer");

  // or Trainer at Large
  if (trainer === undefined) {
    trainer = coaches.find(c => c.status === "coaching" && c.position === "Trainer at Large");
  }

  await renderCoach(doc, trainer);

  // Rest
  const rest = coaches
    .filter(
      ({ id, status, position }) =>
        status === "coaching" &&
        position !== "Head Coach" &&
        ![headCoach, trainer]
          .filter(Boolean)
          .map(({ id }) => id)
          .includes(id)
    )
    .slice(0, 3);

  for (var element of rest) {
    await renderCoach(doc, element);
  }

  doc.restore();
}

async function renderHomeCoaches(doc, { home: { coaches } }) {
  doc.save().translate(35, 456);

  await renderCoaches(doc, { coaches });

  doc.restore();
}

async function renderVisitorCoaches(doc, { visitor: { coaches } }) {
  doc.save().translate(517, 456);

  await renderCoaches(doc, { coaches });

  doc.restore();
}

async function renderReferees(doc, { referees }) {
  doc.save().translate(270, 530);
  const rowHeight = 17;
  const [nameColumnWidth, signatureColumnWidth, externalIdColumnWidth] = [195, 141, 102];

  const padding = 4;
  const imagePadding = 1;

  // Blank
  const rows = [
    {
      label: "Referee"
    },
    {
      label: "Referee/Linesman"
    },
    {
      label: "Linesman"
    },
    {
      label: "Linesman 4 OFFICIAL SYSTEM"
    }
  ];

  doc.save();

  for (var { label } of rows) {
    doc.save();

    doc
      .rect(0, 0, nameColumnWidth, rowHeight)
      .stroke()
      .text(label, padding, padding)
      .translate(nameColumnWidth, 0)
      .rect(0, 0, signatureColumnWidth, rowHeight)
      .stroke()
      .translate(signatureColumnWidth, 0)
      .rect(0, 0, externalIdColumnWidth, rowHeight)
      .stroke();

    doc.restore();

    doc.translate(0, rowHeight);
  }

  doc.restore();

  referees = [
    ...referees.filter(r => r.position === "Head Referee"),
    ...referees.filter(r => r.position === "Referee"),
    ...referees.filter(r => r.position === "Linesman")
  ];

  for (var { name, signature, externalId } of referees) {
    doc.save();

    doc.text(name, -padding, padding + 2, {
      width: nameColumnWidth,
      align: "right"
    });

    doc.translate(nameColumnWidth, 0);

    if (signature) {
      await asyncImage(doc, signature, imagePadding, imagePadding, {
        height: rowHeight - 2 * imagePadding
      });
    }

    doc.translate(signatureColumnWidth, 0);

    doc.text(externalId, 0, padding + 2, {
      width: externalIdColumnWidth,
      align: "center"
    });

    doc.restore();

    doc.translate(0, rowHeight);
  }

  doc.restore();
}

const renderTimestamps = (doc, { startTime, endTime }) => {
  const [width, height] = [47, 52];
  const margin = 2;
  const fieldWidth = width - margin * 2;

  doc.save().translate(713, 546);

  doc.rect(0, 0, width, height).stroke();

  doc.translate(margin, margin);

  doc.text("Time Game", 0, 0);

  const renderField = ({ label, value }) => {
    doc.save();

    doc.text(label);

    doc.translate(0, 18);

    doc
      .text(value, 0, 0, { width: fieldWidth, align: "center" })
      .moveTo(0, 8)
      .lineTo(fieldWidth, 8)
      .stroke();

    doc.restore();
  };

  renderField({ label: "Started", value: startTime });

  doc.translate(0, 21);

  renderField({ label: "Ended", value: endTime });

  doc.restore();
};

const renderBranding = async (doc, { associationLogo, leagueLogo }) => {
  doc.save();
  // Always display GameSheet Inc. logo in bottom left
  doc.translate(0, 526);
  doc.translate(8, 0).image(logo, 0, 0, {
    width: 250
  });

  // display their League or Association logo in the bottom right (if present)
  const theirLogo = !!leagueLogo ? leagueLogo : (!!associationLogo ? associationLogo : null);

  if (!theirLogo) {
    // if they don't have a logo, put ours in there again
    doc.translate(756, 0);
    doc.image(logo, 0, 0, {
      width: 250
    });
  } else {
    doc.translate(756, 4);
    await asyncImage(doc, theirLogo, 0, 0, {
      width: 230,
      height: 70,
      fit: [230, 70],
      align: "center"
    });
  }
  doc.restore();
};

const renderRefereeReport = (doc, game) => {
  const width = 600;
  const minRowHeight = 25;
  const padding = 4;

  const gameDetails = gameLengthDetails(game)

  const columns = {
    team: {
      label: "TEAM",
      width: 140
    },
    number: {
      label: "NO.",
      width: 20
    },
    player: {
      label: "PLAYER",
      width: 90
    },
    period: {
      label: gameDetails.shortPeriodName,
      width: 20
    },
    length: {
      label: "MIN",
      width: 20
    },
    code: {
      label: "CODE",
      width: 30
    },
    note: {
      label: "NOTE",
      width: 160
    },
    by: {
      label: "BY",
      width: 110
    }
  };

  doc.save().translate(355, 22);

  doc.fontSize(12).text("REFEREE REPORT", 0, 0);

  doc.translate(0, 20);

  doc
    .moveTo(0, 0)
    .lineTo(width, 0)
    .lineWidth(0.3)
    .stroke();

  doc.save();

  for (var [, { label, width: columnWidth }] of Object.entries(columns)) {
    doc.fontSize(8).text(label, 0, padding);

    doc.translate(columnWidth, 0);
  }

  doc.restore();

  doc.translate(0, minRowHeight).fontSize(8);

  for (var {
    team,
    number,
    player,
    period,
    length,
    code,
    note: { type, content },
    referee: { position, name }
  } of game.refereeReports) {
    doc.save();

    let rowHeight = minRowHeight;

    if (type === "audio") {
      content = "See GameSheet Dashboard for audio note";
    }

    const contentHeight =
      doc.heightOfString(content, {
        width: columns.note.width
      }) + padding;

    if (contentHeight > minRowHeight) {
      rowHeight = contentHeight;
    }

    doc.text(team, 0, padding, { width: columns.team.width, align: "left" }).translate(columns.team.width, 0);

    doc.text(number, 0, padding, { width: columns.number.width, align: "left" }).translate(columns.number.width, 0);

    doc.text(player, 0, padding, { width: columns.player.width, align: "left" }).translate(columns.player.width, 0);

    doc.text(setPeriodName(period), 0, padding, { width: columns.period.width, align: "left" }).translate(columns.period.width, 0);

    doc.text(length, 0, padding, { width: columns.length.width, align: "left" }).translate(columns.length.width, 0);

    doc.text(code, 0, padding, { width: columns.code.width, align: "left" }).translate(columns.code.width, 0);

    doc.text(content, 0, padding, { width: columns.note.width, align: "left" }).translate(columns.note.width, 0);

    doc.text(`${position} (${name})`, 0, padding, {
      width: columns.by.width,
      align: "left"
    });

    doc.restore();

    doc
      .moveTo(0, 0)
      .lineTo(width, 0)
      .lineWidth(0.2)
      .stroke();

    doc.translate(0, rowHeight);
  }

  doc.restore();
};

const renderGameNotes = (doc, { scorekeeperNotes }) => {
  const width = 280;
  const minRowHeight = 25;
  const padding = 4;

  const columns = {
    type: {
      label: "TYPE",
      width: 60
    },
    note: {
      label: "NOTE",
      width: 160
    },
    by: {
      label: "BY",
      width: 60
    }
  };

  doc.save().translate(35, 22);

  doc.fontSize(12).text("GAME NOTES", 0, 0);

  doc.translate(0, 20);

  doc
    .moveTo(0, 0)
    .lineTo(width, 0)
    .lineWidth(0.3)
    .stroke();

  doc.save();

  for (var [, { label, width: columnWidth }] of Object.entries(columns)) {
    doc.fontSize(9).text(label, 0, padding);

    doc.translate(columnWidth, 0);
  }

  doc.restore();

  doc.translate(0, minRowHeight).fontSize(8);

  for (var {
    type,
    note: { type: noteType, content },
    data
  } of scorekeeperNotes) {
    doc.save();

    let rowHeight = minRowHeight;

    if (noteType === "audio") {
      content = "See GameSheet Dashboard for audio note";
    }

    content = [data, content].filter(Boolean).join(", ");

    const contentHeight =
      doc.heightOfString(content, {
        width: columns.note.width
      }) + padding;

    if (contentHeight > minRowHeight) {
      rowHeight = contentHeight;
    }

    doc.text(type, 0, padding, { width: columns.type.width, align: "left" }).translate(columns.type.width, 0);

    doc
      .text(content, 0, padding, {
        width: columns.note.width,
        align: "left"
      })
      .translate(columns.note.width, 0);

    doc.text("Scorekeeper", 0, padding, {
      width: columns.by.width,
      align: "left"
    });

    doc.restore();

    doc
      .moveTo(0, 0)
      .lineTo(width, 0)
      .lineWidth(0.2)
      .stroke();

    doc.translate(0, rowHeight);
  }

  doc.restore();
};

const renderTeamShootoutAttempts = (doc, options) => {
  const { shotFirst, rows, label, attempts } = options;
  const headerHeight = 23.5;
  const rowWidth = 460;
  const rowHeight = 18;
  const padding = 4;

  const columns = [
    {
      title: "PLAYER",
      width: 200
    },
    {
      title: "GOALIE",
      width: 200
    },
    {
      title: "RESULT",
      width: 60
    }
  ];

  doc.save();

  doc
    .rect(0, 0, rowWidth, headerHeight)
    .stroke()
    .fontSize(10)
    .text(shotFirst ? `${label} *` : label, 0, padding * 2, {
      width: rowWidth,
      align: "center"
    });

  doc.translate(0, headerHeight);

  doc.save();

  for (var { title, width } of columns) {
    doc
      .rect(0, 0, width, headerHeight)
      .stroke()
      .text(title, 0, padding * 2, { width, align: "center" });

    doc.translate(width, 0);
  }

  doc.restore();

  doc.translate(0, headerHeight);

  const nameWithNumber = ({ firstName, lastName, number }) => `(${number}) ${firstName} ${lastName}`;

  for (var { shooter, goalie, result } of attempts) {
    doc.save();

    const shooterLabel = nameWithNumber(shooter);
    const goalieLabel = nameWithNumber(goalie);
    const resultLabel = result.toUpperCase();

    doc
      .rect(0, 0, columns[0].width, rowHeight)
      .stroke()
      .text(shooterLabel, 4, padding, { width: columns[0].width });

    doc.translate(columns[0].width, 0);

    doc
      .rect(0, 0, columns[1].width, rowHeight)
      .stroke()
      .text(goalieLabel, 4, padding, { width: columns[1].width });

    doc.translate(columns[1].width, 0);

    doc
      .rect(0, 0, columns[2].width, rowHeight)
      .stroke()
      .text(resultLabel, 0, padding, {
        width: columns[2].width,
        align: "center"
      });

    doc.translate(columns[2].width, 0);

    doc.restore();

    doc.translate(0, rowHeight);
  }

  // Blank

  for (var i = 0; i < rows - attempts.length; i++) {
    doc.save();

    for (var { width: columnWidth } of columns) {
      doc.rect(0, 0, columnWidth, rowHeight).stroke();

      doc.translate(columnWidth, 0);
    }

    doc.restore();

    doc.translate(0, rowHeight);
  }

  if (shotFirst) {
    doc.text("* Shot first", 4, padding, { width: rowWidth });
  }

  doc.restore();
};

function renderShootout(doc, { shootout, home, visitor }) {
  doc.save().translate(35, 22);

  doc.fontSize(14).text("SHOOTOUT SUMMARY", 4, 8, { width: 945, align: "center" });

  doc.translate(0, 30).save();

  const homeAttempts = shootout.homeAttempts;
  const visitorAttempts = shootout.visitorAttempts;
  const { shotFirst } = shootout;

  const rows = Math.max(...[homeAttempts.length, visitorAttempts.length]);

  renderTeamShootoutAttempts(doc, {
    rows,
    attempts: homeAttempts,
    label: home.title.toUpperCase(),
    shotFirst: shotFirst === "home"
  });

  doc.save().translate(485, 0);

  renderTeamShootoutAttempts(doc, {
    rows,
    attempts: visitorAttempts,
    label: visitor.title.toUpperCase(),
    shotFirst: shotFirst === "visitor"
  });

  doc.restore();
}

export default async function renderGameSheetToPDF(gamesheet) {
  const doc = new PDFDocument({
    size: "legal",
    layout: "landscape"
  });
  const stream = doc.pipe(blobStream());

  gamesheet.associationLogo =
    gamesheet.associationLogo && gamesheet.associationLogo.includes("imagedelivery.net")
      ? `${gamesheet.associationLogo}/256`
      : gamesheet.associationLogo;

  gamesheet.leagueLogo =
    gamesheet.leagueLogo && gamesheet.leagueLogo.includes("imagedelivery.net")
      ? `${gamesheet.leagueLogo}/256`
      : gamesheet.leagueLogo;

  doc.fontSize(8).lineWidth(0.5);

  renderNumber(doc, gamesheet);

  renderDate(doc, gamesheet);
  renderLocation(doc, gamesheet);
  renderDivision(doc, gamesheet);
  renderLeague(doc, gamesheet);
  renderCategory(doc, gamesheet);
  renderPlayoff(doc, gamesheet);

  renderScore(doc, gamesheet);

  renderGameType(doc, gamesheet);

  renderScorekeeper(doc, gamesheet);

  await renderCurfew(doc, gamesheet);

  renderFlood(doc, gamesheet);

  renderLengthOfGame(doc, gamesheet);

  renderHomePlayers(doc, gamesheet);
  renderHomeSuspended(doc, gamesheet);
  renderHomePenalties(doc, gamesheet);
  renderHomeScoring(doc, gamesheet);
  renderHomeSummary(doc, gamesheet);

  await renderHomeCoaches(doc, gamesheet);

  renderHomeShots(doc, gamesheet);

  renderVisitorPlayers(doc, gamesheet);
  renderVisitorSuspended(doc, gamesheet);
  renderVisitorPenalties(doc, gamesheet);
  renderVisitorScoring(doc, gamesheet);
  renderVisitorSummary(doc, gamesheet);

  await renderVisitorCoaches(doc, gamesheet);

  renderVisitorShots(doc, gamesheet);

  await renderReferees(doc, gamesheet);

  renderTimestamps(doc, gamesheet);

  await renderBranding(doc, gamesheet);

  if (gamesheet.shootout.hasShootout) {
    doc.addPage();

    renderShootout(doc, gamesheet);
  }

  if (gamesheet.scorekeeperNotes.length > 0 || gamesheet.refereeReports.length > 0) {
    doc.addPage();

    renderGameNotes(doc, gamesheet);

    if (gamesheet.refereeReports.length > 0) {
      renderRefereeReport(doc, gamesheet);
    }
  }

  doc.end();

  return new Promise(resolve => stream.on("finish", () => resolve(stream.toBlob("application/pdf"))));
}


const gameLengthDetails = (game) => {
  // periods {[x: string]: number};

  const allPeriods = game.periods

  //Total Periods not related to OT or SO
  const nonOTPeriods = Object.keys(game.periods).filter((key) => !isNaN(parseInt(key)));

  // set long period name
  let longPeriodLabel = ""

  if (nonOTPeriods.length === 2) {
    longPeriodLabel = "Half"
  } else if (nonOTPeriods.length === 4) {
    longPeriodLabel = "Quarter"
  } else {
    longPeriodLabel = "Period"
  }

  let shortPeriodName = ""

  if (nonOTPeriods.length === 2) {
    shortPeriodName = "HF"
  } else if (nonOTPeriods.length === 4) {
    shortPeriodName = "QT"
  } else {
    shortPeriodName = "PER"
  }


  return {
    nonOTPeriods,
    longPeriodLabel,
    shortPeriodName,
    allPeriods
  }
}

const setPeriodName = (period) => {
    if(!period){
      return ""
    }
    
    if (period.length === 1) {
      return period;
    } else {
      const otNumber = period.match(/\d+/)[0];
      return `OT${otNumber}`;
    }
}