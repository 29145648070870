import { useCallback } from "react";

export default function useDateInputChange(dispatchValue) {
  const setDateValue = useCallback(
    date => {
      if (!date || typeof date === "string") {
        return;
      }

      dispatchValue(date.format("YYYY-MM-DD"));
    },
    [dispatchValue]
  );

  return setDateValue;
}
