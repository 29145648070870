import React, { Fragment } from "react";

import { Table } from "reactstrap";
import { Link } from "react-router-dom";

import EmptyListMessage from "@/components/EmptyListMessage";
import PageLoader from "@/components/PageLoader";
import TotalCount from "@/components/TotalCount";
import AbilityButton from "@/components/AbilityButton";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ScheduledGame from "./ScheduledGame";

import useScheduledGamesList from "./hooks/useScheduledGamesList";
import useScheduledGamesListPagination from "./hooks/useScheduledGamesListPagination";

import "./List.scss";

function List() {
  const games = useScheduledGamesList();
  const { seasonId } = useCurrentSeasonContext();

  const { isListLoaded, isListLoading, totalCount, filteredCount, isFilterDirty } = useScheduledGamesListPagination();

  if (isListLoaded && !isListLoading && games.length === 0) {
    return (
      <EmptyListMessage>
        {isFilterDirty ? (
          "We couldn't find any scheduled games matching your criteria. Try adjusting your search terms or reducing your filter selections."
        ) : (
          <Fragment>
            <p>There are no scheduled games</p>
            <AbilityButton
              subject={{ type: "scheduledGames", seasonId }}
              action="create"
              outline
              color="success"
              tag={Link}
              to={`/seasons/${seasonId}/games/scheduled/new`}
              size="sm"
            >
              Create New Scheduled Game
            </AbilityButton>
          </Fragment>
        )}
      </EmptyListMessage>
    );
  }

  return (
    <PageLoader isLoaded={isListLoaded} isLoading={isListLoading}>
      <div className="scheduled-games-page__list">
        <TotalCount plural="games" singular="game" totalCount={totalCount} filteredCount={filteredCount} />
        <Table striped borderless>
          <thead>
            <tr>
              <th scope="col">Visiting team</th>
              <th scope="col">Home team</th>
              <th scope="col">
                <span className="visuallyhidden">Actions</span>
              </th>
              <th scope="col">Divisions</th>
              <th scope="col">Date</th>
              <th scope="col">Start Time</th>
              <th scope="col">Number</th>
              <th scope="col">Game Type</th>
              <th scope="col">Location</th>
            </tr>
          </thead>
          <tbody>
            {games.map(game => (
              <ScheduledGame key={game.id} {...game} />
            ))}
          </tbody>
        </Table>
      </div>
    </PageLoader>
  );
}

export default List;
