import { useEffect } from "react";

import useScheduledGamesListPagination from "./useScheduledGamesListPagination";

export default function useScheduledGamesListLoader() {
  const { loadPage, isListLoaded, isListLoading } = useScheduledGamesListPagination();

  useEffect(
    () => {
      if (!isListLoaded && !isListLoading) {
        loadPage(1);
      }
    },
    [loadPage, isListLoaded, isListLoading]
  );
}
