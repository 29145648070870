import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import {
  getCurrentPage,
  getTotalPages,
  loadingRoutine,
  getListIsLoaded,
  getListIsLoading,
  getTotalCount,
  getFilteredCount
} from "@/redux/scheduledGamesList";

import { getFilterIsDirty } from "@/redux/scheduledGamesFilter";

export default function useCompletedGamesListPagination() {
  const { seasonId } = useCurrentSeasonContext();
  const dispatch = useDispatch();

  const isListLoaded = useSelector(getListIsLoaded);
  const isListLoading = useSelector(getListIsLoading);
  const totalCount = useSelector(getTotalCount);
  const filteredCount = useSelector(getFilteredCount);
  const isFilterDirty = useSelector(getFilterIsDirty);
  const totalPages = useSelector(getTotalPages);
  const currentPage = useSelector(getCurrentPage);

  const loadPage = useCallback(pageNumber => dispatch(loadingRoutine({ pageNumber, seasonId })), [dispatch, seasonId]);

  return {
    loadPage,
    totalPages,
    currentPage,
    isListLoaded,
    isListLoading,
    totalCount,
    filteredCount,
    isFilterDirty
  };
}
